import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Filtration = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-resurfacing.jpg" className="top">
      <h1>Filtration and Pumps</h1>
    </BgImage>
    <section className="content">
      <h2>Pumps</h2>
      <p>
        At the heart of any filtration system is the pump. Get a too powerful
        pump and you’ll damage your equipment. Get a too weak pump and you won’t
        be able to run your equipment. We ensure that you pick just the right
        pump for your Swimming Pool or Spa whether it be for filtration,
        pressure or operating jets.
      </p>
      <h2>Filters</h2>
      <p>
        We have an enormous range of filters available that filter even up to 10
        microns. Our installers are fully trained in the latest technology and
        are able to install both sand and zeolite filters. We will work out the
        filter that is most appropriate for bather load, maintenance and the
        hydraulics of the system. Whatever your filtration needs we have it.
      </p>
      <h2>Chlorinators</h2>
      <p>
        We have the widest range of salt and freshwater chlorinators available
        to suit any size pool or spa. We are happy to help you to pick just the
        right chlorinator so as to ensure that all the algae and bacteria will
        be eliminated from your swimming pool without you having to constantly
        service your equipment.
      </p>
      <h2>Automation</h2>
      <p> </p>
    </section>
  </Layout>
)

export default  Filtration
